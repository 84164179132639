import { useEffect, useRef } from 'react'
import * as d3 from 'd3-7'

const useD3Chart = (draw: (elem: d3.Selection<SVGSVGElement, any, any, undefined>) => void, ...dependencies: Array<any>) => {
    const ref = useRef<SVGSVGElement | null>(null)
    useEffect(() => {
        if (!ref.current) {
            return
        }
        draw(d3.select(ref.current))
        return () => {}
        // eslint-disable-next-line
    }, [ref.current, ...dependencies])

    return ref
}

export default useD3Chart
