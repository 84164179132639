// redux
import {
    getJwtToken,
    getUserInfoJWT,
    sessionExists,
    setSession
} from 'store/slices/authJwt/helpers'
import axios from 'axios'
import axiosInstance from 'utils/axios'
import {
    getAvatar,
    loginSuccess,
    logoutSuccess,
    registerSuccess,
    startLoading,
    stopLoading
} from 'store/slices/authJwt/authJwt'
import {
    fetchCompanyHierarchyList2,
    fetchCompanySettings,
    getCompanySubscription
} from 'store/slices/company'
import { fetchFilterData } from 'store/slices/filters'
import { Dispatch } from 'redux'
import { loadColorIntensity } from 'store/slices/reports/colorIntensity'
import {
    getImpactGeographyList,
    getInitiativeDivisionThemesList,
    getInitiativeDivisionThemesMappingRecords,
    getTagList
} from 'store/slices/initiative';
import {
    getPartnerGeography,
    getPartnerOrganization,
    getPartnerScales,
    getPartnerStakeholderList,
} from 'store/slices/impact/partner';
import { useAppDispatch, useAppSelector } from 'hooks/useAppRedux';
import { User } from '../@types/account'
import { PCA, NWPCA } from 'msa';

// ----------------------------------------------------------------------


import { browserInfo } from 'utils/browserInfo';
import { isEmpty } from 'lodash';
import { fetchUserSettings } from '../store/slices/settings'
import { getActivity } from '../store/slices/impact/activity'


type LoginParams = {
    email: string
    password: string
    isRememberMe: boolean
    captchaToken?: string
}

type VerifyParams = {
    email: string
    code: string
}

type RegisterParams = {
    email: string
    password: string
    name: string
    companyName: string
    captchaToken: any
}
type ChangePasswordParams = {
    email: string
    code: string
    password: string
    confirmPassword: string
}

export const userLogoutCleanUp = () => {
    //sessionStorage.removeItem('sessionId')
    sessionStorage.clear()
    //localStorage.removeItem('userLocation')
    localStorage.clear()
    try {
       // amplitude.getInstance().clearUserProperties()
    } catch (_) {
        //
    }
    setSession(null)
}

export const logoutFn = (dispatch: (arg: any) => void) => {
        dispatch(startLoading())

        dispatch(async () => {
            try {
                await PCA.logoutRedirect({
                    // Return false if you would like to stop navigation after local logout
                    onRedirectNavigate: (url) => false
                }).then();
            } catch (_) {
                // ignore
            }
            try {
                await NWPCA.logoutRedirect({
                    // Return false if you would like to stop navigation after local logout
                    onRedirectNavigate: (url) => false
                })
            } catch (_) {
                // ignore
            }
        })


        axiosInstance
            .get('/umm/api/account/LogOff')
            .then(() => {
                userLogoutCleanUp()
                dispatch(logoutSuccess())
            })
            .catch(() => {
                // pass
            })
            .finally(() => {
                dispatch(stopLoading())
            })
}

export const updateUserSession = (user: Partial<User>, logout: VoidFunction) => {
    let userLocation = { lat: '', lon: '', query: '', country: '' } /// localStorage.getItem('userLocation')||
    try {
        userLocation = JSON.parse(localStorage.getItem('userLocation') || '{}')
    } catch (err) {
        /// pass
        userLocation = { lat: '', lon: '', query: '', country: '' }
    }
    const browserData = browserInfo()
    const localSessionId = sessionStorage.getItem('sessionId') || ''
    axiosInstance
        .post('/umm/api/Account/CreateUpdateUserSession?request=d', {
            UserID: user?.id,
            SessionID: localSessionId,
            Latitude: userLocation?.lat || '',
            Longitude: userLocation?.lon || '',
            IPAddress: userLocation?.query || '',
            Country: userLocation?.country || '',
            Email: user?.email,
            Username: user?.contactName,
            Status: true,
            VisitCount: 0,
            IsDeleted: false,
            Browser: browserData.browserName,
            ClientOS: browserData.browserPlatform,
            LogoutTime: null,
            Id: '00000000-0000-0000-0000-000000000000',
            CreatedAt: null,
            LastModifiedAt: null,
        })
        .then(({ data }) => {
            const { sessionId } = data?.rm || {}
            if (isEmpty(localSessionId) && !isEmpty(sessionId)) {
                sessionStorage.setItem('sessionId', sessionId)
            }
            if (getJwtToken() !== data?.responseData?.accessToken) {
                logout()
            }
            /// nothing
        })
        .catch((error) => {
            if (axios.isAxiosError(error) || error?.response?.status) {
                const status = error?.response?.status
                if (status === 401 && sessionExists()) {
                    logout()
                }
            }
        })
}

export function loadAppForUser(access_token: string | null) {
    // @ts-ignore
    return async (dispatch: Dispatch<any>, getState) => {
        setSession(access_token)
        const user = getUserInfoJWT();
        dispatch(loginSuccess({ user }))

        if (user?.userRole === 'Service Admin') {
            return
        }

        await Promise.all([
            dispatch(fetchUserSettings()),
            dispatch(fetchCompanySettings()),
            dispatch(getCompanySubscription()),
            dispatch(getAvatar()),
            dispatch(loadColorIntensity()),
            dispatch(fetchFilterData()),
            dispatch(getPartnerOrganization()),
            dispatch(getPartnerGeography()),
            dispatch(getImpactGeographyList()),
            dispatch(getPartnerScales()),
            dispatch(getPartnerStakeholderList()),
            dispatch(getInitiativeDivisionThemesList()),
            dispatch(getInitiativeDivisionThemesMappingRecords()),
            dispatch(fetchCompanyHierarchyList2()),
            dispatch(getTagList()),
            dispatch(getActivity())
        ])

        updateUserSession(user, () => logoutFn(dispatch))

        const companySettings = getState().company.settings

        try {
            // @ts-ignore
            window.Bugsnag?.setUser(user.id, user.email, user.contactName)
        } catch (_) {
            ///
        }

        import('@hotjar/browser').then(({ default: Hotjar }) => {
            const siteId = 3575100;
            const hotjarVersion = 6;
            try {
                Hotjar.init(siteId, hotjarVersion);
                Hotjar.identify(user?.id||'', { email: user?.email||'', contactName: user?.contactName||'' });
            } catch (_) {
               // console.log('Hotjar Error', _)
            }
        }).catch((err) => {
            console.log(err)
        })

        try {
            const HelpCrunchUser = {
                email: user?.email || '',
                name: user?.contactName || '',
                ///phone: data['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'] || '',
                company: companySettings?.Name || '',
                user_id: user?.id || '',
            }

            // @ts-ignore
            window?.HelpCrunch('init', 'changecompass', {
                applicationId: 1,
                applicationSecret: 'lLXWL5Vuqzfo+8RCWY7w8z8EQ0rOoaSPCs3R7QIL/wZzd7utU7JtlLov1rVMQKo+yfFcnICUuR3UjFD9kywTvg==',
                user: HelpCrunchUser,
            })

            // @ts-ignore
            window?.HelpCrunch('updateUser', HelpCrunchUser)
            // @ts-ignore
            window?.HelpCrunch('showChatWidget')

        } catch (e) {
            console.error(e)
        }

        try {
            // @ts-ignore
            window.Userback = window?.Userback || {};

            // @ts-ignore
            window.Userback?.init('32108|58441|GpIAjfRO72CAheWg8N7sZlloH', {
                email: user?.email || '',
                name: user?.contactName || '',
            });

            // @ts-ignore
            window.Userback.identify(user.id, {
                email: user.email || '',
                name: user.contactName || '',
                full_name: user.contactName || '',
                account_id: user.id || '',
                company: companySettings?.Name || '',
            })

            // @ts-ignore
            window.Userback.setData({
                email: user?.email || '',
                name: user?.contactName || '',
                full_name: user?.contactName || '',
                account_id: user?.id || '',
                company: companySettings?.Name || '',
            });
        } catch (e) {
            ///
        }
        // @ts-ignore
        window?.pendo?.initialize({
            visitor: {
                id: user?.id || '',
                email: user?.email || '',
                full_name: user?.contactName || '',
                company: companySettings?.Name || '',
            },
            account: {
                id: user?.id || '',
                name: user?.contactName || '',
                company: companySettings?.Name || '',
            },
        })

        // setAuthenticated
    }
}

export interface useAuthProps {
    method: string
    user: User
    isCompanyAdmin: boolean
    isSuperAdmin: boolean
    isViewer: boolean
    isCompanyUser: boolean
    isLoading: boolean
    isAuthenticated: boolean
    login: (data: LoginParams) => void
    register: (data: RegisterParams) => void
    verify: (data: VerifyParams) => void
    resend: ({ email }: { email: string }, callback?: (msg: string) => void) => void
    logout: () => void
    resetPassword: (email: any, captchaToken: any, callback?: (msg: string) => void) => void
    verifyForgot: (data: VerifyParams) => void
    changePassword: (data: ChangePasswordParams, callback?: (msg: string) => void) => void
}

export default function useAuth(): useAuthProps {
    // JWT Auth
    const dispatch = useAppDispatch()
    const { user, isLoading, isAuthenticated } = useAppSelector((state) => state.authJwt)

    // JWT Auth
    return {
        method: 'jwt',
        user,
        isCompanyAdmin: user?.userRole === 'Company Admin',
        isSuperAdmin: user?.userRole === 'Service Admin',
        isViewer: user?.userRole === 'Company Viewer',
        isCompanyUser: user?.userRole === 'Company User',
        isLoading,
        isAuthenticated,

        login: ({ email, password, isRememberMe, captchaToken = '' }: LoginParams) =>
            dispatch(async () => {
                // @ts-ignore //
                const response = await axios.post('/umm/api/account/login', {
                    email,
                    password,
                    Password: password, /// REMOVE ME
                    isRememberMe,
                    captchaToken,
                })
                const data = (response?.data?.responseData || response.data) || {}

                const { access_token: accessToken } = data
                if (!accessToken) {
                    throw new Error(response?.data?.message || data?.message)
                }
                await dispatch(startLoading())
                await dispatch(loadAppForUser(accessToken))
                await dispatch(stopLoading())
            }),

        register: ({ email, password, companyName, captchaToken }: RegisterParams) =>
            dispatch(async () => {
                await axios.post('/umm/api/account/Register', {
                    email,
                    password,
                    Password: password,
                    confirmPassword: password,
                    name: companyName,
                    companyName,
                    captchaToken,
                })
                //const {accessToken, user} = response.data;
                window.localStorage.setItem('registerEmail', email)
                dispatch(registerSuccess({ user: { email } }))
            }),
        verify: ({ email, code }: VerifyParams) =>
            dispatch(async () => {
                const response = await axios.post('/umm/api/account/ConfirmEmail', {
                    userId: email,
                    code,
                })
                const { access_token: accessToken } = response?.data?.responseData || response.data
                dispatch(loadAppForUser(accessToken))
            }),
        resend: ({ email }: { email: string }, callback?: (msg: string) => void) =>
            dispatch(async () => {
                const response = await axios.get('/umm/api/account/ReSendEmailConfirm', {
                    params: { emailAddress: email },
                })
                callback?.(response.data?.message)
            }),

        logout: () => logoutFn(dispatch),
        resetPassword: (email: any, captchaToken: any, callback?: (msg: string) => void) =>
            dispatch(async () => {
                const response = await axios.post('/umm/api/account/Forgotpassword', {
                    email,
                    captchaToken,
                })
                callback?.(response?.data?.message)
            }),

        verifyForgot: ({ email, code }: VerifyParams) =>
            dispatch(async () => {
                await axios.post('/umm/api/account/ResetPassword', {
                    userId: email,
                    code,
                })
            }),
        changePassword: ({ email, code, password, confirmPassword }: ChangePasswordParams, callback?: (msg: string) => void) =>
            dispatch(async () => {
                try {
                    const response = await axios.post('/umm/api/account/ResetPassword', {
                        userId: email,
                        code,
                        Password: password,
                        confirmPassword: password,
                    })
                    if (response?.data?.status !== 1) {
                        throw Error(response?.data?.message)
                    }
                    callback?.(response?.data?.message || 'Opps some error ocurred')
                } catch (err) {
                    // @ts-ignore
                    const message = err?.response?.data?.message
                    // @ts-ignore
                    const userNoFound = err?.response?.data?.responseData['$.userId']||null

                    if (userNoFound) {
                        throw Error('User not found')
                    }
                    throw Error(message||'Failed to change password')
                }
            }),
    }
}
