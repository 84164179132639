import React, { useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
// material
import { Avatar, Box, Button, Divider, MenuItem, Typography } from '@mui/material'
// components
import { MIconButton } from 'components/@material-extend'
import MenuPopover from 'components/MenuPopover'
import useAuth from 'hooks/useAuth'
import AvatarDialog from './AvatarDialog'
import { isEmpty } from 'lodash'
// ----------------------------------------------------------------------

const MENU_OPTIONS: any[] = [
    /*{
      label: 'Home',
      icon: homeFill,
      linkTo: '/',
    },
    {
      label: 'Profile',
      icon: personFill,
      linkTo: '#',
    },
    {
      label: 'Settings',
      icon: settings2Fill,
      linkTo: '#',
    },*/
]

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const anchorRef = useRef(null)

    const { user, logout } = useAuth()

    const [open, setOpen] = useState(false)
    const [isAvatarDialogOpen, setAvatarDialogOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const userGreetings = [user?.firstName, user?.lastName].filter((i) => i)

    return (
        <>
            <MIconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                }}
            >
                <Avatar
                    alt='My Avatar'
                    src={user.avatarSrc}
                />
            </MIconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: 220 }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography
                        variant='subtitle1'
                        noWrap
                    >
                        {isEmpty(userGreetings) ? user.contactName : userGreetings.join('')}
                    </Typography>
                    <Typography
                        variant='body2'
                        sx={{ color: 'text.secondary' }}
                        noWrap
                    >
                        {user?.email}
                    </Typography>
                </Box>

                <Divider sx={{ my: 1 }} />

                {MENU_OPTIONS.map((option) => (
                    <MenuItem
                        key={option.label}
                        to={option.linkTo}
                        component={RouterLink}
                        onClick={handleClose}
                        sx={{ typography: 'body2', py: 1, px: 2.5 }}
                    >
                        <Box
                            component={option.icon}
                            sx={{
                                mr: 2,
                                width: 24,
                                height: 24,
                            }}
                        />

                        {option.label}
                    </MenuItem>
                ))}
                <Box sx={{ px: 2, mt: '7px' }}>
                    <Button
                        fullWidth
                        color='inherit'
                        variant='outlined'
                        onClick={() => {
                            setAvatarDialogOpen(true)
                            handleClose()
                        }}
                    >
                        Change Avatar
                    </Button>
                </Box>
                <Box sx={{ px: 2, my: '7px' }}>
                    <Button
                        fullWidth
                        color='inherit'
                        variant='outlined'
                        onClick={logout}
                    >
                        Logout
                    </Button>
                </Box>
            </MenuPopover>
            <AvatarDialog
                isOpen={isAvatarDialogOpen}
                onClose={() => setAvatarDialogOpen(false)}
            />
        </>
    )
}
