import React, { useEffect, useState } from 'react'
import DashboardNavbar from './DashboardNavbar'
import DashboardSidebar from './DashboardSidebar'
import { useMediaQuery } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { getItemSession, saveItemSession } from 'utils/storage';
import useClosableSnackbar from 'hooks/useClosableSnackbar';
import useAuth from '../../hooks/useAuth'
import { useIdleTimer } from 'react-idle-timer'

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
})

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingBottom: theme.spacing(10),
    backgroundColor: '#E5E8EB',
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        //paddingLeft: '28px',
        //paddingRight: '28px',
    },
}))

// ----------------------------------------------------------------------

type DashboardLayoutProps = {
    children?: React.ReactNode
    title?: string
    defaultTitle?: string
}

export default function DashboardLayout({ children, title, defaultTitle }: DashboardLayoutProps) {
    const [open, setOpen] = useState(false)
    const isMobile = useMediaQuery('(max-width:1000px)')
    const theme = useTheme()

    const { user, isAuthenticated, logout } = useAuth()
    const { closableEnqueueSnackbar } = useClosableSnackbar()

    useIdleTimer({
        timeout: (user.autoLogoutDuration > 0 ? user.autoLogoutDuration * 60 : 3600 ) * 1000,
        onIdle: () => {
            if (isAuthenticated && user.autoLogoutDuration > 0) {
                logout()
            }
        },
        debounce: 500,
    })

    useEffect(() => {
        const fn = setInterval(() => {
            const items = getItemSession('notification', [])
            saveItemSession('notification', [])
            items.forEach((item: {type: string, message: string}[]) => {
                // @ts-ignore
                closableEnqueueSnackbar(item.message, item.type)
            })
        }, 400)
        return () =>  clearInterval(fn)
    }, [])


    return (
        <RootStyle>
            <DashboardNavbar title={title} defaultTitle={defaultTitle} onOpenSidebar={() => setOpen(true)} />
            <DashboardSidebar
                isMobile={isMobile}
                isOpenSidebar={open}
                onCloseSidebar={() => setOpen(false)}
            />
            <MainStyle
                sx={{
                    transition: theme.transitions.create('margin', {
                        duration: theme.transitions.duration.complex,
                    }),
                }}
            >
                {children}
            </MainStyle>
        </RootStyle>
    )
}
