import { createSlice } from '@reduxjs/toolkit'
import axiosInstance, { getSandboxInstance, responseData } from 'utils/axios'
import { Dispatch } from 'redux'
import { isSandBox } from '../settings'
import { format, parseISO, startOfDay, endOfDay, addMinutes } from 'date-fns'
import { reportError } from 'utils/errorReport'
import { RootState } from 'store'
import { PartnerName, PartnerType } from '../../../@types/settings'
import axios from 'axios';
import { filterImpactBasic } from 'utils/reportFilters';
import { createSelector } from 'reselect';
import { getXAxisDataPeriod } from 'views/reports/Heatmaps/utils';
import { ImpactList } from '../../../@types/initiative';
import { impactTotalTiming, parseImpactTimingString } from 'utils/date';
import { ImpactTiming } from '../../../@types/impact';
import { impactWorkingHours } from 'utils/iniatives';
import { getImpactLevelsTree } from 'store/slices/company';


interface HeatMapReportData {
    changeTolerance: Array<Record<string, any>>
    customerTypes: Array<Record<string, any>>
    divisionStrategies: Array<Record<string, any>>
    editableFields: Array<Record<string, any>>
    hierarchyItems: Record<string, any>
    impacts: Array<Record<string, any>>
    initiativeBenefit: Array<Record<string, any>>
    numberOfCustomers: Array<Record<string, any>>
    isCompanyAdmin: boolean
    oclLevel: Array<Record<string, any>>
    resourcingLevel: Array<Record<string, any>>
    partnerNames: PartnerName[]
    partnerTypes: PartnerType[]
}

interface HeatMapProps {
    isLoading: boolean
    error: boolean
    data: HeatMapReportData
    filtersData: {
        initiatives: any[],
        companyStrategy: any[],
        phase: any[],
        owner: any[],
        changeType: any[],
        activities: any[],
        stakeholders: any[],
        contact: any[],
        partnerStakeholders: any[],
        partnerImpactScales: any[],
    }
}

const initialState: HeatMapProps = {
    isLoading: false,
    error: false,
    data: {
        changeTolerance: [],
        customerTypes: [],
        divisionStrategies: [],
        editableFields: [],
        hierarchyItems: {},
        impacts: [],
        initiativeBenefit: [],
        numberOfCustomers: [],
        isCompanyAdmin: false,
        oclLevel: [],
        resourcingLevel: [],
        partnerNames: [],
        partnerTypes: [],
    },
    filtersData: {
        initiatives: [],
        companyStrategy: [],
        phase: [],
        owner: [],
        changeType: [],
        activities: [],
        stakeholders: [],
        contact: [],
        partnerStakeholders: [],
        partnerImpactScales: [],
    }
}

const slice = createSlice({
    name: 'heatmap',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
            state.error = false
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET PRODUCTS
        getHeapMapSuccess(state, action) {
            state.isLoading = false
            state.data = action.payload
        },
        setHeapMapFilterData(state, action) {
            state.filtersData = action.payload
        },
    },
})

export default slice.reducer

let heatMapAxiosSource = axios.CancelToken.source()

export function getHeatMapReport(startDate: Date | null, endDate: Date | null, silent = false) {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        heatMapAxiosSource.cancel()
        heatMapAxiosSource = axios.CancelToken.source()
        dispatch(slice.actions.getHeapMapSuccess({}))
        //@ts-ignore
        !silent && dispatch(slice.actions.startLoading())

        try {
            const response = await (isSandBox(getState()) ? await getSandboxInstance() : axiosInstance).get('/api/Report/ReportHeatmapData', {
                params: {
                    impactFromDate: format(startDate || new Date(), 'y-MM-d'),
                    impactToDate: format(endDate || new Date(), 'y-MM-d'),
                },
                cancelToken: heatMapAxiosSource.token
            })
            const data = responseData(response.data) || {}

            const initiatives = new Set()
            const companyStrategy = new Set()
            const phase = new Set()
            const owner = new Set()
            const changeType = new Set()
            const activities = new Set()
            const stakeholders = new Set()
            const partnerStakeholders = new Set()
            const partnerImpactScales = new Set()
            const contact = new Set()


            const impacts = filterImpactBasic(data?.impacts || []).map((impact: Record<string, any>) => {
                initiatives.add(impact.Initiatives)
                impact.CompanyStrategy.forEach(companyStrategy.add.bind(companyStrategy))
                phase.add(impact.Phase)
                owner.add(impact.Owner)
                impact.ChangeType.forEach(changeType.add.bind(changeType))
                contact.add(impact.Contact)
                impact.Activities.forEach(activities.add.bind(activities))
                impact.Stakeholders.forEach(stakeholders.add.bind(stakeholders))
                impact.PartnerStakeholder?.forEach(partnerStakeholders.add.bind(partnerStakeholders))
                partnerImpactScales.add(impact?.PartnerImpactScaleId)
                return {
                    ...impact,
                    impactRange: getXAxisDataPeriod(impact),
                    From: addMinutes(startOfDay(parseISO(impact.From)), 10),
                    To: addMinutes(endOfDay(parseISO(impact.To)), -10),
                }
            });

            dispatch(slice.actions.setHeapMapFilterData({
                initiatives: Array.from(initiatives).sort(),
                companyStrategy: Array.from(companyStrategy).sort(),
                phase: Array.from(phase).sort(),
                owner: Array.from(owner).sort(),
                changeType: Array.from(changeType).sort(),
                activities: Array.from(activities).sort(),
                stakeholders: Array.from(stakeholders).sort(),
                partnerStakeholders: Array.from(partnerStakeholders).sort(),
                contact: Array.from(contact).sort(),
                partnerImpactScales: Array.from(partnerImpactScales).filter((i) => i),
            }))

            dispatch(slice.actions.getHeapMapSuccess({ ...data, impacts }))

        } catch (error) {
            if (axios.isCancel(error)) {
                return;
            }
            reportError(error)
            !silent && dispatch(slice.actions.hasError(error))
        }
    }
}

export const getHeatMapFiltersData = createSelector(
    (state: RootState) => state.reports.heatmap,
    (state): Record<string, any> => state.filtersData
)


export const changeImpactItem = (impact: ImpactList) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const reportData = getState().reports.heatmap
        const impactLevels = getImpactLevelsTree(getState())

        dispatch(slice.actions.getHeapMapSuccess({
            ...reportData,
            impacts: (reportData.data?.impacts || []).map((item: Record<string, any>) => {
                if (item.ImpactId === impact.Id) {
                    const Periods = JSON.stringify(impact.Timing)
                    const timing = parseImpactTimingString(Periods)
                    //console.log('changeImpactItem', impact.Timing)
                    const totalTiming = impactTotalTiming(timing as ImpactTiming)
                    const workingHours = impactWorkingHours(impact, impactLevels)
                    return {
                        ...item,
                        ChangeImpactName: impact.Name,
                        impactRange: getXAxisDataPeriod({ ...item, Periods }),
                        From: addMinutes(startOfDay(totalTiming.From), 10),
                        To: addMinutes(endOfDay(totalTiming.To), -10),
                        ImpactHours: workingHours,
                        WorkingHours: workingHours,
                        ImpactLevel: impact.Level,
                    }
                }
                return item
            })
        }))
    }
}
