import React from 'react'
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import MButton from 'components/@material-extend/MButton'

export type Order = 'asc' | 'desc'

export interface ColumnHeader {
    label: string | any[]
    field: string
    numeric?: boolean
    boolean?: boolean
    formatter?: (val: any, row: Record<string, any>) => void
    editable?: boolean
    allEmpty?: boolean
    minWidth?: number
    allowEmpty?: boolean
    editTextArea?: boolean
    placeholder?: string
    editFormatter?: (val: any, onChange: (data: Record<string, any>) => void, row: Record<string, any>, state: Record<string, any>) => React.ReactNode
}

interface EnhancedTableProps {
    columns: ColumnHeader[]
    onRequestSort?: (event: React.MouseEvent<unknown>, property: any) => void
    order?: Order
    orderBy?: string
    addAction: VoidFunction
    addNew: boolean
    addEmptyActionCell: boolean
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order = 'asc', orderBy, columns, onRequestSort, addAction, addNew, addEmptyActionCell } = props
    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
        if (onRequestSort) onRequestSort(event, property)
    }
    /// align={headCell?.numeric ? 'right' : 'left'}

    return (
        <TableHead>
            <TableRow sx={{ borderBottom: '1px solid rgba(145, 158, 171, 0.24)' }}>
                {columns.map((headCell) => {
                    const sx: Record<string, any> = { backgroundColor: 'transparent' }
                    if (headCell?.minWidth) {
                        sx.minWidth = headCell.minWidth
                    }
                    return (
                        <TableCell
                            key={headCell.field}
                            padding='none'
                            sx={sx}
                            sortDirection={orderBy === headCell.field ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.field}
                                direction={orderBy === headCell.field ? order : 'asc'}
                                onClick={createSortHandler(headCell.field)}
                            >
                                {headCell.label}
                                {orderBy === headCell.field ? (
                                    <span
                                        style={{
                                            border: 0,
                                            clip: 'rect(0 0 0 0)',
                                            height: 1,
                                            margin: -1,
                                            overflow: 'hidden',
                                            padding: 0,
                                            position: 'absolute',
                                            top: 20,
                                            width: 1,
                                        }}
                                    >
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    )
                })}

                {addNew ? (
                    <TableCell
                        size='small'
                        align='right'
                        sx={{ backgroundColor: 'transparent', padding: 0, margin: 0 }}
                    >
                        <MButton
                            variant='contained'
                            color='error'
                            size='small'
                            sx={{ padding: '2px 0px 3px 0px !important' }}
                            onClick={addAction}
                        >
                            Add {<AddIcon fontSize='small' />}
                        </MButton>
                    </TableCell>
                ) : (
                    addEmptyActionCell && (
                        <TableCell
                            sx={{ backgroundColor: 'transparent' }}
                            size='small'
                            align='right'
                        />
                    )
                )}
            </TableRow>
        </TableHead>
    )
}

export default EnhancedTableHead
