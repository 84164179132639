import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { isEmpty } from 'lodash'

export interface ConfirmDeleteItemTableProps {
    onDelete: (item: Record<string, any>) => void
    handleClose: VoidFunction
    item: Record<string, any>
}

const ConfirmDeleteItemTable = ({ onDelete, item, handleClose }: ConfirmDeleteItemTableProps) => {
    const [open, setOpen] = useState<any>(false)

    useEffect(() => {
        setOpen(!isEmpty(item))
    }, [item])

    return (
        <>
            <Dialog
                open={open}
                disableEnforceFocus
                onBackdropClick={() => {}}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'xs'}
                scroll='body'
            >
                <DialogTitle>
                    <Box
                        display='flex'
                        alignItems='left'
                    >
                        <Box flexGrow={1}>
                            <Typography
                                variant='h4'
                                align='center'
                            >
                                Delete item
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                onClick={handleClose}
                                size='large'
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography align='center'>Do you really want to delete record?</Typography>
                </DialogContent>

                <DialogActions sx={{ justifyContent: 'flex-end', }}>
                    <Button
                        onClick={handleClose}
                        variant='contained'
                        color='inherit'
                    >
                        Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        onClick={() => {
                            onDelete(item)
                            handleClose()
                        }}
                        variant='contained'
                        color='error'
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ConfirmDeleteItemTable
