import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Popper(theme: Theme) {
    return {
        MuiPopper: {
            styleOverrides: {
                root: {
                    zIndex: 999999999999
                },
                paper: {
                    boxShadow: theme.customShadows.z12,
                },
            },
        },
    }
}
