import React, { useState } from 'react'
import { matchPath, NavLink as RouterLink, useLocation } from 'react-router-dom'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// material
import {
    Box,
    BoxProps,
    Collapse,
    List,
    ListItemButton,
    ListItemButtonProps,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    styled
} from '@mui/material'
import useAuth, { useAuthProps } from 'hooks/useAuth'
import { isEmpty, startsWith } from 'lodash'
import { pxToRem } from 'utils/formatFontSize'
import { useAppSelector } from '../hooks/useAppRedux'
import { getCompanySettings } from '../store/slices/company'
import { CompanySettingsProps } from '../@types/settings'

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled(({ children, ...props }: React.ComponentProps<any>) => (
    <ListSubheader
        disableSticky
        disableGutters
        {...props}
    >{children}</ListSubheader>
))(({ theme }) => ({
    ...theme.typography.overline,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    color: theme.palette.text.primary,
}))

interface ListItemStyleProps extends ListItemButtonProps {
    component?: any
    to?: string
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({ theme }) => ({
    ...theme.typography.body2,
    fontSize: pxToRem(15),
    fontWeight: 500,
    width: '100%',
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: '20px',
    paddingRight: '20px',
    color: theme.palette.text.secondary,
    '&:before': {
        top: 0,
        right: 0,
        width: 3,
        bottom: 0,
        content: "''",
        display: 'none',
        position: 'absolute',
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        backgroundColor: '#FFC107',
    },
    '&:hover': {
        backgroundColor: 'rgba(255, 193, 7, 0.12)',
    },
}))

const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})

// ----------------------------------------------------------------------

type NavItemProps = {
    title: string
    path: string
    icon?: JSX.Element
    info?: JSX.Element
    allowed?: (auth: useAuthProps & CompanySettingsProps) => boolean
    children?: NavItemProps[]
}

function NavItem({ item, isShow }: { item: NavItemProps; isShow?: boolean | undefined }) {
    const { pathname } = useLocation()
    const { title, path, icon, info, children } = item

    const auth = useAuth()
    const companySettings = useAppSelector(getCompanySettings)


    const isActiveRoot = path ? Boolean(matchPath({ path, end: false }, pathname)) : false
    const isActiveChild = path ? startsWith(pathname, path) : false

    const [open, setOpen] = useState(isActiveRoot)

    const handleOpen = () => {
        setOpen(!open)
    }

    const activeRootStyle = {
        color: 'primary.main',
        fontWeight: 'fontWeightMedium',
        bgcolor: 'rgba(255, 193, 7, 0.24)',
        '&:before': { display: 'block' },
    }

    const textStyle = {
        color: '#C4CDD5',
        ///...typography.body2,
    }

    if (children) {
        return (
            <>
                <ListItemStyle
                    onClick={handleOpen}
                    sx={{
                        ...((isActiveRoot || (!isShow && isActiveChild)) && activeRootStyle),
                    }}
                >
                    <ListItemIconStyle sx={textStyle}>{icon}</ListItemIconStyle>
                    {isShow && (
                        <>
                            <ListItemText
                                disableTypography
                                primary={title}
                                sx={textStyle}
                            />
                            {info && info}
                            <Box
                                component={open ? KeyboardArrowDownIcon : ArrowForwardIosIcon}
                                sx={{ width: 16, height: 16, ml: 1, ...textStyle }}
                            />
                        </>
                    )}
                </ListItemStyle>

                {isShow && (
                    <Collapse
                        in={open}
                        timeout='auto'
                        unmountOnExit
                    >
                        <List
                            component={Box}
                            disablePadding
                        >
                            {children
                                .filter((item) => !item.allowed || item.allowed({ ...auth, ...companySettings }))
                                .map((item) => {
                                const { title, path, icon } = item
                                const isActiveSub = path ? startsWith(pathname, path) : false

                                return (
                                    <ListItemStyle
                                        key={title}
                                        component={RouterLink}
                                        to={path}
                                        sx={{
                                            ...(isActiveSub && activeRootStyle),
                                            pl: 5,
                                        }}
                                    >
                                        <ListItemIconStyle sx={textStyle}>{icon && icon}</ListItemIconStyle>
                                        <ListItemText
                                            disableTypography
                                            primary={title}
                                            sx={textStyle}
                                        />
                                    </ListItemStyle>
                                )
                            })}
                        </List>
                    </Collapse>
                )}
            </>
        )
    }

    return (
        <ListItemStyle
            component={RouterLink}
            to={path}
            sx={{
                ...(isActiveRoot && activeRootStyle),
            }}
        >
            <ListItemIconStyle sx={textStyle}>{icon && icon}</ListItemIconStyle>
            {isShow && (
                <>
                    <ListItemText
                        disableTypography
                        primary={title}
                        sx={textStyle}
                    />
                    {info && info}
                </>
            )}
        </ListItemStyle>
    )
}

interface NavSectionProps extends BoxProps {
    isShow?: boolean | undefined
    navConfig: {
        subheader: string
        items: NavItemProps[]
    }[]
}

export default function NavSection({ navConfig, isShow = true, ...other }: NavSectionProps) {
    const auth = useAuth()
    const companySettings = useAppSelector(getCompanySettings)

    return (
        <Box {...other}>
            {navConfig.map((list, i) => {
                const { subheader, items } = list

                return (
                    <List
                        key={`navConfig${i}`}
                        component={Box}
                        disablePadding
                        sx={{ display: 'flex', flexDirection: 'column', alignItems: isShow ? 'unset' : 'center' }}
                    >
                        {isShow && !isEmpty(subheader) && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
                        {items
                            .filter((item) => !item.allowed || item.allowed({ ...auth, ...companySettings }))
                            .map((item: NavItemProps) => (
                                <NavItem
                                    key={item.title}
                                    item={item}
                                    isShow={isShow}
                                />
                            ))}
                    </List>
                )
            })}
        </Box>
    )
}
