import { createSlice } from '@reduxjs/toolkit'
import axiosInstance, { responseData } from 'utils/axios'
import { Dispatch } from 'redux'
import { reportError } from 'utils/errorReport'
import { Activity } from '../../../@types/impact';
import { filterEmpty } from '../../../utils/array'

interface ActivityState {
    isLoading: boolean
    error: boolean,
    activities: Activity[],
}

const initialState: ActivityState = {
    isLoading: false,
    error: false,
    activities: [],
}

const slice = createSlice({
    name: 'activity',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET PRODUCTS
        getActivitySuccess(state, action) {
            state.isLoading = false
            state.activities = action.payload
        },
    },
})

export default slice.reducer

export function getActivity() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axiosInstance.post('/api/ImpactActivity/ImpactActivityList')
            const res = filterEmpty(responseData(response.data))
            dispatch(slice.actions.getActivitySuccess(res))
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}
