import { Theme, Components } from '@mui/material/styles'
// ----------------------------------------------------------------------

export default function Dialog(theme: Theme): Components {
    return {
        MuiDialog: {
            defaultProps: { PaperProps: { sx: { paddingY: '10px' } } },
            styleOverrides: {
                root: {
                   // zIndex: 9999999,
                },
                paper: {
                    boxShadow: theme.customShadows.z24,
                    '&.MuiPaper-rounded': {
                        borderRadius: theme.shape.borderRadiusMd,
                    },
                    '&.MuiDialog-paperFullScreen': {
                        borderRadius: 0,
                    },
                    '&.MuiDialog-paper .MuiDialogActions-root': {
                        padding: theme.spacing(3),
                    },
                    '@media (max-width: 600px)': {
                        margin: theme.spacing(2),
                    },
                    '@media (max-width: 663.95px)': {
                        '&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody': {
                            maxWidth: '100%',
                        },
                    },
                    minHeight: '100px'
                },
                paperFullWidth: {
                    width: '100%',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(1, 3, 0),
                    '@media (max-width: 1400px)': {
                        padding: '10px 20px !important',
                    },

                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    borderTop: 0,
                    borderBottom: 0,
                    marginTop: theme.spacing(1),
                    padding: theme.spacing(1),
                    '@media (max-width: 1400px)': {
                        marginTop: '0px !important',
                    },
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    '& > :not(:first-of-type)': {
                        marginLeft: theme.spacing(1.5),
                    },
                    '@media (max-width: 1400px)': {
                        padding: '0px 5px !important',
                    },
                },
            },
        },
    }
}
